import { findChapterProgress } from "app/model/podcast";
import { useRef, useEffect } from "react";
import { useIntersection } from "react-use";
import {
  Category,
  PodcastChapter,
  PodcastChaptersProgress,
} from "services-hooks/types";
import MemoLoader from "../icons/Loader";
import { ChapterItem } from "./chapter-item.component";
import styles from "./podcast-view.module.scss";

interface ChapterListProps {
  podcast: Category;
  chapters: PodcastChapter[];
  loading: boolean;
  authCallback: VoidFunction;
  chapterProgress: PodcastChaptersProgress[];
  startPlayback: (
    chapter: PodcastChapter,
    index: number,
    chapterList: PodcastChapter[],
    podcast: Category
  ) => void;
  downloadAvailable: boolean;
  loadNextPage: VoidFunction;
  showOfflineAuthGuard: VoidFunction;
  reloadProgress: VoidFunction;
}
export const ChapterList: React.FC<ChapterListProps> = ({
  chapters,
  loading,
  chapterProgress,
  startPlayback,
  downloadAvailable,
  loadNextPage,
  podcast,
  showOfflineAuthGuard,
  authCallback,
  reloadProgress,
}) => {
  const loadMoreTriggerRef = useRef(null);
  const intersection = useIntersection(loadMoreTriggerRef, {
    rootMargin: "0px",
    threshold: 1,
  });
  useEffect(() => {
    if (intersection?.isIntersecting) {
      loadNextPage();
    }
  }, [intersection]);
  return (
    <div className={styles.podcast_chapter_list_wrapper}>
      {loading ? (
        <div className={styles.loader}>
          <MemoLoader />
        </div>
      ) : (
        <div className={styles.podcast_chapter_list}>
          {chapters.map((chapter, chapterIndex) => {
            const progress = findChapterProgress(
              chapterProgress,
              chapter.permalink!
            );
            return (
              <ChapterItem
                podcast={podcast}
                key={chapter.permalink}
                chapter={chapter}
                progress={progress}
                reloadProgress={reloadProgress}
                index={chapterIndex}
                authCallback={authCallback}
                downloadAvailable={downloadAvailable}
                startPlayback={(chapter) => {
                  startPlayback(chapter, chapterIndex, chapters, podcast);
                }}
                showOfflineAuthGuard={showOfflineAuthGuard}
              />
            );
          })}
          <div ref={loadMoreTriggerRef} />
        </div>
      )}
    </div>
  );
};
