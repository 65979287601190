import { Category, PodcastChapter } from "../../../services-hooks/types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useGetPillowListenV2ProgressPodcast,
  useGetPillowPodcastChapters,
} from "../../../services-hooks/hooks";
import styles from "./podcast-view.module.scss";
import { selectPlayerState } from "../../store/slices/player";
import { useAppSelector } from "../../store/store";
import MemoPlay from "../icons/Play";
import MemoPause from "../icons/Pause";
import MemoHeartOutline from "../icons/HeartOutline";
import clsx from "clsx";
import { useFavorites } from "app/hooks/favorites.hook";
import Loader from "../icons/Loader";
import MemoHeartFull from "../icons/HeartFull";
import { FavoritesAuthGuardPopup } from "../auth/favorites-auth-guard.component";
import { selectUserState } from "app/store/slices/user";
import { usePlayerContext } from "app/context/player.context";
import { useFeaturesContext } from "app/context/features.context";
import { ChapterList } from "./chapter-list.component";
import { useOfflineContext } from "app/context/offline.context";
import { checkIfCanBeDownloaded } from "app/utils/offline";
import { OfflineAuthGuardPopup } from "../auth/offline-auth-guard.component";
import { usePlayback } from "app/hooks/player.hook";
import { AuthGuardPopup } from "../auth/auth.component";
import { useTranslate } from "app/hooks/lang";
import MemoAngleDown from "../icons/AngleDown";
import Globe from "../icons/Globe";

const enum PodcastTabs {
  All = "All",
  Downloaded = "Downloaded",
  Unplayed = "Unplayed",
}

interface PodcastViewProps {
  podcast: Category;
}

export const PodcastView: React.FC<PodcastViewProps> = ({ podcast }) => {
  const { isPlaying, isMediaLoading } = usePlayerContext();
  const { downloadEnabled, showRadioWebsite } = useFeaturesContext();
  const { offlineChapters } = useOfflineContext();
  const { session } = useAppSelector(selectUserState);
  const [showOfflineAuthGuard, setShowOfflineAuthGuard] = useState(false);

  const {
    data: podcastChapters,
    isLoading,
    fetchNextPage,
  } = useGetPillowPodcastChapters(podcast.permalink!, {
    pageSize: 10,
  });
  const chapterList =
    podcastChapters?.pages?.reduce((chapterList, page) => {
      const newList = [...chapterList, ...(page.data.body?.content || [])];
      return newList;
    }, [] as PodcastChapter[]) || [];
  const {
    data: podcastProgressData,
    refetch: refetchProgress,
    isLoading: progressLoading,
  } = useGetPillowListenV2ProgressPodcast(podcast.permalink!, {
    enabled: false,
  });
  const chaptersProgress = podcastProgressData?.data.data || [];
  const chapterListOffline = useMemo(
    () =>
      chapterList.filter(
        (chapter) =>
          !!offlineChapters?.find(
            (offlineChapter) => offlineChapter.permalink === chapter.permalink
          )
      ),
    [chapterList]
  );
  const chapterListUnplayed = useMemo(
    () =>
      chapterList.filter(
        (chapter) =>
          !chaptersProgress?.find(
            (podcastProgressItem) =>
              podcastProgressItem.chapter_permalink === chapter.permalink
          )
      ),
    [chapterList, chaptersProgress]
  );
  useEffect(() => {
    if (session?.user) {
      refetchProgress();
    }
  }, [podcast, session]);
  const { toggleFavorite, loading, isFavoriteCategory } = useFavorites();
  const { startChapterPlayback, stopPlayback } = usePlayback();

  const playFirst = () => {
    if (chapterList.length > 0) {
      startChapterPlayback(chapterList[0], 0, chapterList, podcast);
    }
  };
  const [activeTab, setActiveTab] = useState(PodcastTabs.All);
  const [showAuthGuard, setShowAuthGuard] = useState(false);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const [downloadCheckLoading, setDownloadCheckLoading] = useState(true);

  const closeAuthGuard = useCallback(() => {
    setShowAuthGuard(false);
  }, [setShowAuthGuard]);

  useEffect(() => {
    if (chapterList.length > 0) {
      if (downloadEnabled) {
        try {
          const first = chapterList[0];
          checkIfCanBeDownloaded(
            first,
            () => {
              setDownloadAvailable(true);
              setDownloadCheckLoading(false);
            },
            () => {
              setDownloadAvailable(false);
              setDownloadCheckLoading(false);
            }
          );
        } catch (e) {
          setDownloadCheckLoading(false);
        }
      } else {
        setDownloadCheckLoading(false);
      }
    }
  }, [podcastChapters, downloadEnabled]);

  const playerState = useAppSelector(selectPlayerState);
  const isPlayingThisPodcast =
    playerState.activeMedia?.podcast?.podcast.permalink === podcast.permalink;
  const canDownload = downloadEnabled && downloadAvailable;
  const t = useTranslate();
  const [descriptionExpandable, setDescriptionExpandable] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const descriptionContainerRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerHeight = descriptionContainerRef.current?.clientHeight || 0;
    const descriptionHeight = descriptionRef.current?.clientHeight || 0;
    if (containerHeight > 0 && descriptionHeight > 0) {
      if (containerHeight < descriptionHeight) {
        setDescriptionExpandable(true);
      }
    }
  }, [podcast.description]);
  useEffect(() => {
    if (!session?.user) {
      if (activeTab === PodcastTabs.Downloaded) {
        setShowOfflineAuthGuard(true);
      }
      if (activeTab === PodcastTabs.Unplayed) {
        setShowAuthGuard(true);
      }
    }
  }, [activeTab, session, showOfflineAuthGuard]);
  const closeAuthGuardCallback = useCallback(() => {
    if (activeTab === PodcastTabs.Unplayed) {
      setActiveTab(PodcastTabs.All);
    }
    closeAuthGuard();
  }, [closeAuthGuard, setActiveTab]);
  const closeOfflineGuardCallback = useCallback(() => {
    if (activeTab === PodcastTabs.Downloaded) {
      setActiveTab(PodcastTabs.All);
    }
    setShowOfflineAuthGuard(false);
  }, [setShowOfflineAuthGuard, setActiveTab]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.podcast_cover}>
        <img src={podcast?.logo} alt={podcast?.name} />
        <div className={styles.podcast_details}>
          <h1>{podcast?.name}</h1>
          <div className={styles.buttons_wrapper} data-navigate-row>
            {isLoading || isMediaLoading ? (
              <button className={styles.action_button}>
                <Loader />
              </button>
            ) : isPlaying &&
              isPlayingThisPodcast &&
              playerState?.activeMedia?.podcast ? (
              <button
                disabled={isLoading}
                className={clsx(styles.action_button, styles.play_button)}
                onClick={stopPlayback}
                data-navigate-item
              >
                <MemoPause />
              </button>
            ) : (
              <button
                data-navigate-item
                disabled={isLoading}
                className={clsx(styles.action_button, styles.play_button)}
                onClick={playFirst}
              >
                <MemoPlay />
              </button>
            )}
            <button
              className={clsx(styles.action_button, styles.favorites_button)}
              onClick={() => toggleFavorite(podcast)}
              data-navigate-item
            >
              {loading ? (
                <Loader />
              ) : isFavoriteCategory(podcast) ? (
                <MemoHeartFull />
              ) : (
                <MemoHeartOutline />
              )}
            </button>
          </div>
        </div>
      </div>

      <div
        className={clsx(styles.podcast_description_container, {
          [styles.expanded]: descriptionExpanded,
          [styles.expandable]: descriptionExpandable,
        })}
      >
        <div className={styles.podcast_description}>
          <h2>{podcast?.baseline}</h2>
          <div
            ref={descriptionContainerRef}
            className={styles.description_container}
          >
            <div ref={descriptionRef} className={styles.description}>
              {podcast?.description}
            </div>
          </div>
        </div>
        {descriptionExpandable && (
          <div
            className={clsx(styles.expander, {
              [styles.open]: descriptionExpanded,
            })}
            data-navigate-row
          >
            <button
              data-navigate-item
              onClick={() => setDescriptionExpanded((value) => !value)}
            >
              <MemoAngleDown />
            </button>
          </div>
        )}
      </div>

      {showRadioWebsite && podcast.web && (
          <a href={podcast.web} target="_blank" rel="noopener" data-navigate-item>
            <button className={styles.website_button}>
              <Globe />
              {t("radio.visit-website")}
            </button>
          </a>
        )}

      <div className={styles.tabs_wrapper}>
        <div className={styles.tabs} data-navigate-row>
          <button
            onClick={() => setActiveTab(PodcastTabs.All)}
            className={clsx(styles.tab, {
              [styles.active]: activeTab === PodcastTabs.All,
            })}
            data-navigate-item
          >
            {t("tabs.all")}
          </button>

          {canDownload && (
            <button
              onClick={() => setActiveTab(PodcastTabs.Downloaded)}
              className={clsx(styles.tab, {
                [styles.active]: activeTab === PodcastTabs.Downloaded,
              })}
              data-navigate-item
            >
              {t("tabs.downloaded")}
            </button>
          )}

          <button
            onClick={() => setActiveTab(PodcastTabs.Unplayed)}
            className={clsx(styles.tab, {
              [styles.active]: activeTab === PodcastTabs.Unplayed,
            })}
            data-navigate-item
          >
            {t("tabs.unplayed")}
          </button>
        </div>
      </div>
      {activeTab === PodcastTabs.All && (
        <ChapterList
          authCallback={() => setShowAuthGuard(true)}
          chapters={chapterList}
          podcast={podcast}
          reloadProgress={refetchProgress}
          loading={isLoading || downloadCheckLoading}
          loadNextPage={fetchNextPage}
          chapterProgress={chaptersProgress}
          startPlayback={startChapterPlayback}
          downloadAvailable={canDownload}
          showOfflineAuthGuard={() => setShowOfflineAuthGuard(true)}
        />
      )}
      {activeTab === PodcastTabs.Downloaded && (
        <ChapterList
          authCallback={() => setShowAuthGuard(true)}
          chapters={chapterListOffline}
          podcast={podcast}
          reloadProgress={refetchProgress}
          loading={isLoading || downloadCheckLoading}
          loadNextPage={fetchNextPage}
          chapterProgress={chaptersProgress}
          startPlayback={startChapterPlayback}
          downloadAvailable={canDownload}
          showOfflineAuthGuard={() => setShowOfflineAuthGuard(true)}
        />
      )}
      {activeTab === PodcastTabs.Unplayed && (
        <ChapterList
          authCallback={() => setShowAuthGuard(true)}
          chapters={chapterListUnplayed}
          podcast={podcast}
          reloadProgress={refetchProgress}
          loading={isLoading || downloadCheckLoading || progressLoading}
          loadNextPage={fetchNextPage}
          chapterProgress={chaptersProgress}
          startPlayback={startChapterPlayback}
          downloadAvailable={canDownload}
          showOfflineAuthGuard={() => setShowOfflineAuthGuard(true)}
        />
      )}
      {showAuthGuard && (
        <AuthGuardPopup
          onClose={closeAuthGuardCallback}
          onSuccess={closeAuthGuard}
        />
      )}
      {showOfflineAuthGuard && (
        <OfflineAuthGuardPopup onClose={closeOfflineGuardCallback} />
      )}
    </div>
  );
};
