import { Category, PodcastChaptersProgress } from "services-hooks/types";

export const findChapterProgress = (
  chapterProgressList: PodcastChaptersProgress[],
  permalink: string
) => {
  return chapterProgressList.find(
    (chapterProgress) => chapterProgress.chapter_permalink === permalink
  );
};
