import styles from "./podcast-episodes.module.scss";
import React from "react";
import { useRouter } from "next/router";
import Loader from "app/components/icons/Loader";
import clsx from "clsx";
import { PodcastView } from "app/components/podcast-view/podcast-view.component";
import { useNavigateBack } from "app/hooks/navigation.hook";
import Head from "next/head";
import { useTranslate } from "app/hooks/lang";
import { BackButtonBar } from "app/components/back-button/back-button.component";
import { getCircularReplacer } from "app/utils/array";
import { getPillowPodcast } from "services-hooks/services";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import { NextSeo } from "next-seo";
import { truncateString } from "app/utils/string";
import { Category } from "services-hooks/types";
import { getServersideLocale } from "app/utils/getServersideLocale";

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}

export const getStaticProps: GetStaticProps<{
  initialData: Category;
}> = async (context) => {
  try {
    const permalink = context.params ? context.params["permalink"] : null;
    if (!permalink) {
      throw new Error("Missing permalink");
    }
    const podcast: Category | null =
      (
        await getPillowPodcast(
          `podcasts/${(permalink as string[])?.join("/")}`,
          {
            headers: {
              authorization: `Bearer ${process.env.SERVER_API_KEY}`,
              "X-PillowLocale": getServersideLocale(context.locale),
            },
          }
        )
      ).data.body?.content || null;
    if (!podcast) {
      return {
        notFound: true,
      };
    }
    return {
      props: {
        initialData: JSON.parse(JSON.stringify(podcast, getCircularReplacer())),
      },
      revalidate: 43200,
    };
  } catch (e) {
    console.error(e);
    return {
      notFound: true,
    };
  }
};

export default function PodcastPage({
  initialData,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter();

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  return (
    <>
      {router.isFallback ? (
        <div className={styles.wrapper}>
          <div className={clsx("loader", styles.loader)}>
            <Loader />
          </div>
        </div>
      ) : (
        <PodcastPageContent initialData={initialData} />
      )}
    </>
  );
}

interface PodcastPageContentProps {
  initialData: Category;
}
const PodcastPageContent: React.FC<PodcastPageContentProps> = ({
  initialData,
}) => {
  const router = useRouter();
  const { permalink } = router.query;
  const parsedPermalink = (permalink as string[])?.join("/") || "";
  const t = useTranslate();
  const goBack = useNavigateBack();
  const pageTitle = initialData.name
    ? `${initialData.name} - Radioline`
    : `Radioline`;
  const pageDesc = initialData.description || "";
  const pageTags = initialData
    ? initialData?.tags
        ?.filter((tag) => tag.tagType !== "audioads")
        .map((tag) => tag.name)
        .join(", ")
    : "Radioline";
  const openGraphImages = initialData?.logo
    ? [
        {
          url: initialData.logo,
          alt: pageTitle,
        },
      ]
    : [];

  const mainTag = initialData.tags?.length ? initialData.tags[0].name : "";

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  return (
    <>
      <Head>{pageTags && <meta name="keywords" content={pageTags} />}</Head>
      <NextSeo
        title={t("seo_title_podcast", {
          var1: initialData.name,
          var2: mainTag,
        })}
        titleTemplate={`%s`}
        description={`${t("seo_description_podcast", {
          var1: initialData.name,
          var2: truncateString(pageDesc, 160),
        })}`.trim()}
        languageAlternates={[
          ...(router.locales || [])
            .filter((locale) => locale !== "defaultLang")
            .map((locale) => ({
              hrefLang: locale,
              href: `https://www.radioline.co/${locale}/podcasts/${parsedPermalink}`,
            })),
          {
            href: `https://www.radioline.co/en/podcasts/${parsedPermalink}`,
            hrefLang: "x-default",
          },
        ]}
        canonical={
          router.locale !== "defaultLang"
            ? `https://www.radioline.co/${router.locale}/podcasts/${parsedPermalink}`
            : `https://www.radioline.co/en/podcasts/${parsedPermalink}`
        }
        openGraph={{
          images: openGraphImages,
        }}
      />
      <BackButtonBar onClick={goBack} />
      <div className={styles.wrapper}>
        <PodcastView podcast={initialData} />
      </div>
    </>
  );
};
